const Global = {
    init() {
        this.sticky();
        this.backButton();
    },
    sticky() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('Sticky', () => ({
                init() {
                    var space_inbetween = 16;
                    var header_height = document.querySelector('header').offsetHeight;
                    header_height = document.body.classList.contains('logged-in') ? header_height + 32 : header_height;
                    var offset_top = header_height + space_inbetween;
                    this.$el.classList.add('sticky');
                    this.$el.style.top = offset_top + 'px';
                },
            }));
        });

        // Listen for scroll events on sticky elements and add the is-sticky class when the trigger is reached
        const sticky_elements = document.querySelectorAll('.sticky');
        const observe_sticky_elements = () => {
            sticky_elements.forEach((el) => {
                const offsetTop = el.getBoundingClientRect().top + window.scrollY;
                const stickyTrigger = offsetTop - window.scrollY;

                // Check if the sticky trigger has been reached
                if (window.scrollY >= stickyTrigger) {
                    el.classList.add('is-sticky');
                } else {
                    el.classList.remove('is-sticky');
                }
            });
        };
        // Listen for scroll events
        window.addEventListener('scroll', observe_sticky_elements);
        // Initial check in case some elements are already sticky on page load
        observe_sticky_elements();
    },
    backButton() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('BackButton', () => ({
                init() {
                    var refferer = document.referrer;

                    if (refferer.includes('/page/')) {
                        this.$el.setAttribute('href', refferer);
                        var text_span = this.$el.querySelector('span');
                        text_span.innerHTML = 'Back to previous page';
                        return;
                    }

                    if (refferer.includes('content-hub')) {
                        this.$el.setAttribute('href', refferer);
                        var text_span = this.$el.querySelector('span');
                        text_span.innerHTML = 'Back to Content Hub';
                        return;
                    }

                    return;
                },
            }));
        });
    },
};

export default Global;
