const Links = {
    init() {
        this.addGlossaryPopup();
    },
    addGlossaryPopup() {
        if (document.body.classList.contains('post-type-archive-glossary') || document.body.classList.contains('single-glossary')) {
            return;
        }

        const glossaryLinks = document.querySelectorAll('a[href*="/glossary/"]');

        // Example: Logging the href attributes of the selected links to the console
        glossaryLinks.forEach((element) => {
            const slug = element.getAttribute('href').split('glossary/').pop().replace('/', '');
            fetch('/wp-json/wp/v2/glossary?slug=' + slug)
                .then((response) => response.json())
                .then((posts) => {
                    if (posts.length > 0) {
                        // Post found, process it here
                        console.log('Post found:', posts[0]);
                        this.openGlossaryPopup(element, posts[0]);
                    } else {
                        // No post found with that slug
                        console.log('No post found with that slug.');
                    }
                })
                .catch((error) => console.error('Error fetching post:', error));
        });
    },
    openGlossaryPopup(element, post) {
        console.log(element);

        var styles = 'style="color: #FAB356; top: -3px;"';
        var size = '18';
        var classes = 'inline ml-xs relative';

        element.innerHTML +=
            '<svg ' +
            styles +
            ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="' +
            size +
            '" height="' +
            size +
            '" class="ono-icons ono-icons-help-square ' +
            classes +
            '" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.759 2h8.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564C22 6.29 22 6.954 22 7.758v8.483c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H7.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 17.71 2 17.046 2 16.242V7.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C6.29 2 6.954 2 7.758 2ZM5.91 4.038c-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819C4 6.361 4 6.943 4 7.8v8.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.156.08.38.145.819.18C6.361 20 6.943 20 7.8 20h8.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 0 0 .874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V7.8c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 0 0-.874-.874c-.156-.08-.38-.145-.819-.18C17.639 4 17.057 4 16.2 4H7.8c-.857 0-1.439 0-1.889.038Zm6.347 3.986a2 2 0 0 0-2.225 1.308 1 1 0 1 1-1.886-.664 4 4 0 0 1 7.773 1.333c0 1.53-1.135 2.54-1.945 3.081a8.044 8.044 0 0 1-1.686.848l-.035.013-.011.003-.004.002h-.002L11.92 13l.316.949a1 1 0 0 1-.634-1.897h.001l.016-.006.074-.027a6.06 6.06 0 0 0 1.172-.6c.69-.46 1.055-.95 1.055-1.419v-.001a2 2 0 0 0-1.662-1.975ZM11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z" fill="currentColor"></path></svg>';

        // Open a popup with the glossary term
        console.log('Open popup with glossary term:', post);
        element.addEventListener('mouseover', (e) => {
            element.className += ' relative';

            var div = document.createElement('div');
            div.className = 'glossary-tooltip';
            div.innerHTML = '<div class="title">' + post.title.rendered + '</div>';
            div.innerHTML += '<div class="content">' + post.content.rendered.replace(/(<([^>]+)>)/gi, '') + '</div>';
            div.innerHTML += '<div class="link"><a href="' + post.link + '">Click link to read more</a></div>';
            element.appendChild(div);

            console.log('Mouseover event:', e);
        });

        element.addEventListener('mouseout', (e) => {
            element.className = element.className.replace(' relative', '');
            element.removeChild(element.lastChild);
        });
    },
};

export default Links;
