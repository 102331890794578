import continents from '@data/continents.json';

const Helpers = {
    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    },
    getOffset(el) {
        el = el.getBoundingClientRect();
        return {
            left: el.right + window.scrollX,
            top: el.top + window.scrollY,
        };
    },
    isArrayEmpty(arr) {
        if (arr === undefined || arr === null) return true;
        return arr.length === 0;
    },
    isNumeric(str) {
        return /^[0-9]+$/.test(str);
    },
    removeHTMLTags(input) {
        return input.replace(/<\/?[^>]+(>|$)/g, '');
    },
    async loadMapSvg(region) {
        // Check if region contains only two characters (assumed to be a country code)
        if (region.length === 2) {
            this.svgPath = window.wp_paths.images + '/world/countries/svg/' + region + '.svg';
        } else {
            this.svgPath = window.wp_paths.images + '/world/continents/svg/' + region + '.svg';
        }

        var worldPath = window.wp_paths.images + '/world/world.svg';

        console.log('Loading SVG:', this.svgPath);

        try {
            let response = await fetch(this.svgPath);

            if (!response.ok) {
                if (response.status === 404) {
                    response = await fetch(worldPath);
                } else {
                    throw new Error(`Error loading SVG for region: ${region}. HTTP Status: ${response.status}`);
                }
            }

            const svgContent = await response.text();

            // Check if the response contains valid SVG content
            if (!svgContent.startsWith('<svg')) {
                throw new Error(`Invalid SVG content for region: ${region}`);
            }

            return svgContent; // Return the valid SVG content
        } catch (error) {
            console.error('Error loading SVG:', error);
            // Return a fallback message or null if the SVG loading fails
            return `<p>${error.message}</p>`; // Optionally, you can return `null` instead of an error message
        }
    },
    async fetchRegionStats(region) {
        if (region.length !== 2) {
            return;
        }

        try {
            const data = await this.fetchJson(window.wp_paths.api + 'coverage/v1/stats/' + region);
            console.log('Region stats:', data);
            return data;
        } catch (error) {
            console.error('Error fetching region stats:', error);
            return null; // Return null or some fallback in case of error
        }
    },
    async fetchJson(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }

            // Check if there is content before parsing JSON
            if (response.status === 204 || response.headers.get('Content-Length') === '0') {
                return null; // No content to parse
            }

            const json = await response.json();
            return json;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            return null; // Return null in case of error
        }
    },

    getCheckmarkSVG() {
        return '<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z" fill="#f76b15"/></svg>';
    },
};

window.Helpers = Helpers;

export default Helpers;
