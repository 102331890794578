const Cards = {
    init() {
        this.CardTags();
    },
    CardTags() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('CardTags', () => ({
                init() {
                    if (window.innerWidth < 768) {
                        return;
                    }

                    const parent_width = this.$el.parentElement.clientWidth;
                    const tags = this.$el.querySelectorAll('.tag');
                    const single_tag_height = tags[0].offsetHeight;
                    const tags_rows = Cards.calculate_rows(this.$el, parent_width);
                    const tags_gaps = tags_rows * 4;
                    const tags_total_height = tags_rows === 1 ? single_tag_height : tags_rows * single_tag_height + tags_gaps;
                    const this_element = this.$el;
                    const more_tag_element = this.$el.querySelector('.more-tag');

                    var tags_start_width = 0;
                    var tags_total_width = Cards.run_tags_visibility(tags, parent_width, tags_start_width);

                    this_element.style.height = single_tag_height + 'px';

                    if (tags_total_width > parent_width) {
                        more_tag_element.classList.remove('hidden');
                    }

                    // Mouseover
                    this.$el.parentElement.addEventListener('mouseover', function () {
                        tags.forEach(function (tag) {
                            tag.style.display = 'block';

                            tag.style.opacity = 1;
                        });
                        this_element.style.height = tags_total_height + 'px';

                        more_tag_element.classList.add('hidden');
                    });

                    // Mouseout
                    this.$el.parentElement.addEventListener('mouseout', function () {
                        Cards.run_tags_visibility(tags, parent_width, tags_start_width);
                        if (tags_total_width > parent_width) {
                            more_tag_element.classList.remove('hidden');
                        }
                        this_element.style.height = single_tag_height + 'px';
                    });
                },
            }));
        });
    },
    run_tags_visibility(tags, parent_width, tags_width) {
        tags_width += (tags.length - 1) * 8;
        var tag_index = 1;
        tags.forEach(function (tag) {
            tags_width += tag.clientWidth;
            if ((tag_index !== tags.length && tags_width > parent_width - 30) || tags_width > parent_width) {
                tag.style.opacity = 0;
                tag.style.display = 'none';
            }
            tag_index++;
        });
        return tags_width;
    },
    calculate_rows(parent, parent_width) {
        const tags = parent.querySelectorAll('.tag');
        var tags_total_width = 0;
        tags_total_width += (tags.length - 1) * 8;
        tags.forEach(function (tag) {
            tags_total_width += tag.offsetWidth;
        });
        let current_row_width = parent_width < tags_total_width ? 30 : 0;
        let row_count = 1;

        for (let i = 0; i < tags.length; i++) {
            const child_width = tags[i].offsetWidth;
            current_row_width += child_width;

            if (current_row_width > parent_width) {
                row_count++;
                current_row_width = child_width;
            }
        }

        return row_count;
    },
};

export default Cards;
