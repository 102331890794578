const Intercom = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('IntercomForm', () => Intercom.IntercomForm);
        });
    },
    IntercomForm() {
        return {
            isLoading: false,
            isSucess: false,
            isError: false,
            isNotValid: false,
            form_data: {
                email: '',
                subject: '',
                message: '',
                consent: '',
            },
            submit() {
                this.isLoading = true;

                for (const key in this.form_data) {
                    if (this.form_data[key] === '') {
                        this.isNotValid = true;
                        this.isLoading = false;
                        return;
                    }
                }

                fetch('/wp-json/intercom/v1/ticket/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.form_data),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('Success:', data);
                        this.isLoading = false;
                        this.isSucess = true;
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        this.isError = false;
                    });
            },
        };
    },
};

export default Intercom;
