import Helpers from '../utilities/helpers';
import queryString from 'query-string';
import IsoCountries from '../../data/countries.json';

const Class = {
    primaryColor: 'primary-1',
    primaryColorHex: '#ab7e63',
    preselectedLocations: [],

    searchModel: '',
    dropdownModel: {},
    filterDataTableModel: [],

    worldMapJsonData: '',
    coverageJsonData: '',

    worldMapJsonUrl: 'https://cdn.growth.onomondo.com/data/worldmap.json',
    coverageJsonUrl: '',
    showWorldMap: true,

    dataTableRef: '',
    mapRef: false,
    locationDropdownSelectRef: false,

    searchValue: new Map(),

    /**
     * Init the main Alpine functions and variables
     */
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('CoverageTableLink', (coverageJsonUrl, preselectedLocations, primaryColor, primaryColorHex, showWorldMap) => ({
                filterModel: [],
                toggleModel: '',
                defaultToggleModel: '',
                searchModel: Class.searchModel,
                coverageJsonData: [],
                headers: [],
                filters: [],
                regions: [],
                countries: [],
                fetchError: false,

                init() {
                    Class.coverageJsonUrl = coverageJsonUrl;
                    Class.preselectedLocations = preselectedLocations;
                    Class.primaryColor = primaryColor;
                    Class.primaryColorHex = primaryColorHex;
                    Class.showWorldMap = showWorldMap;

                    var that = this;

                    Class.fetchJson(Class.coverageJsonUrl)
                        .then(function (result) {
                            Class.coverageJsonData = result;
                            that.coverageJsonData = result;
                            that.headers = result.headers;
                            that.filters = result.filters;
                            that.regions = result.location_dropdown.region;
                            that.countries = result.location_dropdown.country;
                            Class.initDataTable(that.filterModel);
                            Class.this = that;
                            return result;
                        })
                        .then(() => {
                            Class.fetchJson(Class.worldMapJsonUrl).then(function (result) {
                                Class.worldMapJsonData = result;
                                return Class.initMap();
                            });
                        })
                        .then(() => {
                            Class.dropdownInit(this.$refs);
                            this.filterModel = Class.checkUrlParametersForFilterModel(this.filterModel);
                            this.toggleModel = Class.checkUrlParametersForToggleModel(this.toggleModel);
                        })
                        .catch(function (error) {
                            that.fetchError = true;
                            console.error('Error fetching JSON:', error);
                        });

                    this.$watch('toggleModel', (value, oldValue) => {
                        if (oldValue === '') {
                            this.defaultToggleModel = value;
                        } else if (oldValue && value === '') {
                            value = this.defaultToggleModel;
                            this.toggleModel = value;
                        }
                        this.filterModel = this.filterModel.filter((item) => item !== oldValue);
                        this.filterModel.push(value);
                    });

                    this.$watch('filterModel', (value, oldValue) => {
                        Class.filterChange(this.filterModel);
                        console.log('filterModel', this.filterModel);
                    });
                },

                searchChange(value) {
                    Class.prepareSearchAction('search', value);
                },

                resetFilters(event) {
                    event.preventDefault();

                    // Empty models and global variables
                    this.filterModel = [];
                    this.toggleModel = '';
                    Class.dropdownModel = [];
                    Class.searchValue = new Map();

                    // Actions
                    $('.select2-init').val(null).trigger('change');

                    if (Class.mapRef) {
                        Class.mapRef.clearSelectedRegions();
                    }

                    Class.resetColumnSearch();
                    Class.updateUrlParameters();
                },
            }));
        });
    },

    async fetchJson(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            const json = await response.json();
            return json;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            return false;
        }
    },

    updateUrlParameters() {
        var parameters = queryString.parse(location.search);
        var hash = window.location.hash;

        if (Class.searchValue.size > 0) {
            Class.searchValue.forEach(function (value, key, map) {
                if (key == 'search') {
                    parameters.search = value.value;
                }
                if (key == 'location') {
                    parameters.location = value.value.join(',');
                }
            });
        } else {
            parameters = {};
        }

        history.pushState({}, null, '?' + queryString.stringify(parameters) + hash);
        if (hash) {
            var element = document.querySelector(hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 1000);
            }
        }
    },

    checkUrlParametersForFilterModel(filterModel) {
        const parameters = queryString.parse(location.search);

        if (parameters.search) {
            setTimeout(() => {
                var value = Helpers.getUrlParameter('search');
                $('#search_field').val(value).trigger('changed');
                Class.prepareSearchAction('search', value);
                Class.searchModel = value;
            }, 1000);
        }

        if (parameters.filters) {
            var value = Helpers.getUrlParameter('filters');
            value = value.split(',');

            value.forEach((element) => {
                filterModel.push(element);
            });

            Class.filterChange(filterModel);
        }

        if (parameters.location && !Class.preselectedLocations) {
            var preselect_values = Helpers.getUrlParameter('location');
            preselect_values = preselect_values.split(',');
            Class.dropdownModel = preselect_values;

            var checkIftableIsLoaded = setInterval(function () {
                if (Class.dataTableRef) {
                    clearInterval(checkIftableIsLoaded);
                    Class.locationDropdownSelectRef.val(preselect_values).trigger('change');
                    Class.dropdownChange(Class.locationDropdownSelectRef[0].options, preselect_values, 'location');
                    Class.selectedCountriesFromRows('applied');
                }
            }, 500);
        }

        if (parameters.freetrial === 'true') {
            console.log('test');
            setTimeout(() => {
                this.toggleModel = 'poc-free-trial';
            }, 1000);
            console.log(this.toggleModel);
        }

        return filterModel;
    },

    checkUrlParametersForToggleModel(toggleModel) {
        const parameters = queryString.parse(location.search);

        if (parameters.freetrial === 'true') {
            console.log('test');
            toggleModel = 'poc-free-trial';
        }

        return toggleModel;
    },

    /**
     * Dropdown field change action
     * @param {*} options
     * @param {*} value
     * @param {*} name
     * @returns
     */
    dropdownChange(options, value, name) {
        // Save to model
        value = Array.from(options)
            .filter((option) => option.selected)
            .map((option) => option.value);

        Class.prepareSearchAction(name, value);
        Class.selectedCountriesFromRows('applied');

        return value;
    },

    /**
     * Data filters change action
     */
    filterChange(filterModel) {
        var selected_filters = [];

        if (!Class.coverageJsonData.filters) {
            return;
        }

        for (const filter in filterModel) {
            if (Class.coverageJsonData.filters[filterModel[filter]]) {
                selected_filters.push(Class.coverageJsonData.filters[filterModel[filter]]);
            }
        }

        // Check if datatable is loaded
        if (Class.dataTableRef) {
            Class.prepareSearchActionCheckboxes(selected_filters, Object.keys(Class.coverageJsonData.filters));
        } else {
            // Else perform a interval check until its loaded
            var checkIfDataTableIsLoaded = setInterval(function () {
                if (Class.dataTableRef) {
                    clearInterval(checkIfDataTableIsLoaded);
                    Class.prepareSearchActionCheckboxes(selected_filters, Object.keys(Class.coverageJsonData.filters));
                }
            }, 10);
        }
    },

    /**
     * Prepare the data filter checkboxes for search
     * @param {*} selected_filters
     * @param {*} filters
     */
    prepareSearchActionCheckboxes(selected_filters, filters) {
        Class.resetColumnSearch();

        filters.forEach((filter) => {
            var selected_filter = selected_filters.filter((object) => {
                return object.id === filter;
            });

            if (selected_filter.length > 0) {
                selected_filter = selected_filter[0];
                Class.searchValue.set(selected_filter.id, {
                    value: selected_filter.filter_values,
                    column: selected_filter.column_index ? selected_filter.column_index : false,
                });
            } else {
                Class.searchValue.delete(filter);
            }
        });

        Class.prepareSearchAction(false);
    },

    /**
     * Set selected regions on world map with filters
     * @param {*} selected_filters
     */
    setSelectedFiltersRegion(selected_filters) {
        var selectedCountries = [];

        selected_filters.forEach((element) => {
            selectedCountries.push(element.countries);
        });

        if (selected_filters.length > 0) {
            selectedCountries = selectedCountries.reduce((a, b) => a.filter((c) => b.includes(c)));
        }

        // Check if mapRef is loaded
        if (Class.mapRef) {
            Class.mapRef.clearSelectedRegions();
            Class.mapRef._setSelected('regions', selectedCountries);
            return;
        }
        // Else perform a interval check until its loaded
        var checkIfMapIsLoaded = setInterval(function () {
            if (Class.mapRef) {
                clearInterval(checkIfMapIsLoaded);
                Class.mapRef.clearSelectedRegions();
                Class.mapRef._setSelected('regions', selectedCountries);
            }
        }, 100);
    },

    /**
     * Prepare general search in tables
     * @param {*} name
     * @param {*} value
     * @param {*} column
     */
    prepareSearchAction(name, value, column) {
        if (name) {
            Class.searchValue.delete(name);

            Class.searchValue.set(name, {
                value: value,
                column: column ? column : false,
            });
        }

        var global_search_values = [];
        var columns = [];

        Class.dataTableRef.api().search('').draw();

        Class.searchValue.forEach(function (element) {
            if (element.value == '') {
                return;
            }

            if (element.column) {
                columns.push(element);
                return;
            }
            global_search_values.push(element.value);
        });

        Class.doColumnsSearch(columns);
        Class.doGlobalSearch(global_search_values);
        Class.updateUrlParameters();
    },

    /**
     * Reset all column searches
     */
    resetColumnSearch() {
        Class.dataTableRef.api().search('').columns().search('').draw();
    },

    /**
     * Do a column search on the table
     * @param {*} columns
     */
    doColumnsSearch(columns) {
        var offset = Class.checkIfResponsiveIsActive() ? 1 : 0;

        columns.forEach((element) => {
            var value = element.value.join('|');
            Class.dataTableRef
                .api()
                .column(element.column + offset)
                .search(value, true, false)
                .draw();
        });
    },

    checkIfResponsiveIsActive() {
        return Class.dataTableRef.find('thead tr th.dtr-control')[0].style.display !== 'none';
    },

    addRegex(value) {
        if (Array.isArray(value)) {
            var temp_array = [];
            value.forEach((element) => {
                temp_array.push('(?=.*' + element + ')');
            });
            return temp_array;
        }
        return '(?=.*' + value + ')';
    },

    escapeArray(array) {
        var temp_array = [];
        array.forEach((element) => {
            temp_array.push($.fn.dataTable.util.escapeRegex(element));
        });
        return temp_array;
    },

    /**
     * Do a global search on whole table
     * @param {*} search_value
     */
    doGlobalSearch(global_search_values) {
        var search_values = [];
        global_search_values.forEach((value) => {
            if (Array.isArray(value)) {
                value = Class.escapeArray(value);
                value = value.join('|');
                value = Class.addRegex(value);
                search_values.push(value);
            } else {
                value = value.split(' ');
                value = Class.addRegex(value);
                value = value.join('');
                search_values.push(value);
            }
        });

        search_values = search_values.join(' ');

        Class.dataTableRef.api().search(search_values, true, false).draw();
        Class.selectedCountriesFromRows('applied');
    },

    /**
     * Init dropdown fields
     * @param {*} that
     * @param {*} dropdownName
     * @param {*} location_key
     * @param {*} preselect_values
     */
    dropdownInit(ref) {
        var select2 = $(ref.select).select2({
            dropdownParent: $(ref.select).parent(),
            dropdownAutoWidth: true,
        });

        Class.locationDropdownSelectRef = select2;

        select2.on('select2:select', (event) => {
            Class.dropdownModel = Class.dropdownChange(event.target.options, Class.dropdownModel, 'location');
        });

        select2.on('select2:unselect', (event) => {
            Class.dropdownModel = Class.dropdownChange(event.target.options, Class.dropdownModel, 'location');
        });

        if (Class.preselectedLocations.length > 0) {
            Class.dropdownModel = Class.preselectedLocations;
            select2.val(Class.preselectedLocations).trigger('change');
            Class.dropdownChange(select2[0].options, Class.preselectedLocations, 'location');
        }
    },

    getCheckboxMarkup(key, title) {
        var color = Class.primaryColor;
        var html = '';
        html += '<div class="field checkbox checkbox-sm checkbox-right checkbox-' + color + '">';
        html += '<div class="input">';
        html += '<label class="form-check" for="' + key + '_header_filter">';
        html += '<input class="dataTable-column-checkbox" id="' + key + '_header_filter" name="' + key + '_header_filter" type="checkbox" value="' + key + '" x-model="filterModel">';
        html += '<span class="text-' + color + ' border-' + color + ' font-bold uppercase">' + title + '</span>';
        html += '</label>';
        html += '</div>';
        html += '</div>';

        return html;
    },

    /**
     * Init the almighty datatable
     * @param {*} filterModel
     */
    initDataTable() {
        var color = Class.primaryColor;
        var columns = [
            {
                name: 'checkbox',
                title: '',
                data: null,
                className: 'first',
                responsivePriority: 1,
                orderable: false,
                width: '10px',
                render: function (data, type, row, met) {
                    return '<div class="border-' + color + ' hover:bg-' + color + '"></div>';
                },
            },
        ];

        var checkmark = '<svg width="18" height="18" version="2.0"><use href="#checkmark" /></svg>';

        var index = 0;
        for (const header_key in Class.coverageJsonData.headers) {
            var header = Class.coverageJsonData.headers[header_key];

            // Class names
            var class_names = 'td-' + index;
            class_names += !header.priority_column ? ' none dtr-hidden' : '';

            var column = (function (header) {
                return {
                    name: header_key,
                    title: header.filter ? Class.getCheckboxMarkup(header.key, header.title) : header.title,
                    data: header_key,
                    className: class_names,

                    orderable: !header.filter ? true : false,
                    orderSequence: !header.filter ? ['desc', 'asc'] : [],
                    width: 'auto',
                    visible: header.hide_on_frontend ? false : true,
                    render: function (data, type, row, met) {
                        if (type === 'display') {
                            if (header.add_empty_string_value && data == '') {
                                data = header.empty_string_value;
                            }
                            if (header.add_string_replacer) {
                                data = data.replace(header.string_replacer_search_value, header.string_replacer_replacement_value);
                            }
                            if (header.add_checkmark_filter) {
                                if (header.checkmark_filter_true_values.includes(data)) {
                                    data = checkmark;
                                }
                            }

                            if (header.original_key === 'country' || header.original_key === 'network') {
                                data = '<div class="truncate max-w-[100px] lg:max-w-[180px]" >' + data + '</div>';
                            }
                            return data;
                        }
                        return data;
                    },
                };
            })(header);
            columns.push(column);
            index++;
        }

        Class.dataTableRef = jQuery('table.data-table').dataTable({
            ajax: {
                url: Class.coverageJsonUrl,
                dataSrc: 'rows',
            },
            deferRender: false,
            columns: columns,
            dom: 'lrtip',
            lengthChange: false,
            order: [[1, 'asc']],
            //autoWidth: false,
            columnDefs: [
                {
                    className: 'dtr-control hidden lg:block',
                    orderable: false,
                    target: 0,
                    width: '10px',
                },
                {
                    target: 2,
                    responsivePriority: 2,
                    className: 'max-w-[100px] lg:max-w-full rounded-tl-sm rounded-bl-sm lg:rounded-tl-none lg:rounded-bl-none border-l-1 lg:border-l-0',
                },

                {
                    target: 4,
                    responsivePriority: 1,
                },
            ],
            responsive: {
                details: {
                    type: 'column',
                    target: 'tr',
                },
            },
        });

        Class.dataTableRef.api().on('init', function () {
            var checkIfMapIsLoaded = setInterval(function () {
                if (Class.mapRef) {
                    clearInterval(checkIfMapIsLoaded);
                    Class.selectedCountriesFromRows('applied');
                }
            }, 500);
        });

        Class.dataTableRef.api().on('responsive-display', function (e, datatable, row, showHide, update) {
            if (showHide) {
                var row = datatable.row(row).node();
                var child_cell = $(row).next().find('td.child');
                child_cell.append('<div class="details"></div>').find('ul.dtr-details').appendTo(child_cell.find('.details'));
            }
        });
        Class.dataTableRef.css({
            opacity: '1',
            maxHeight: 'auto',
            overflow: 'auto',
        });
        $('.data-table-body').css({
            opacity: '1',
        });
        setTimeout(() => {
            $('.data-table-loader').css({
                opacity: '0',
            });
        }, 500);
        Class.dataTableRef.find('thead tr').addClass('text-' + color);
    },

    selectedCountriesFromRows(searchMode) {
        var countries = [];
        Class.dataTableRef
            .api()
            .rows({ order: 'index', search: searchMode })
            .every(function () {
                countries.push(this.data()['country-code']);
            });

        var selectedCountries = countries.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        });
        Class.setSelectedRegions(selectedCountries);
    },

    setSelectedRegions(selectedCountries) {
        // Check if mapRef is loaded
        if (Class.mapRef) {
            Class.mapRef.clearSelectedRegions();
            Class.mapRef._setSelected('regions', selectedCountries);
            return;
        }
        // Else perform a interval check until its loaded
        var checkIfMapIsLoaded = setInterval(function () {
            if (Class.mapRef) {
                clearInterval(checkIfMapIsLoaded);
                Class.mapRef.clearSelectedRegions();
                Class.mapRef._setSelected('regions', selectedCountries);
            }
        }, 100);
    },

    /**
     * Init the world map
     * @param {*} location_key
     */
    initMap() {
        if (!Class.coverageJsonData || !Class.showWorldMap) {
            return;
        }

        // Assign each country the name from the data sheet based on iso code
        for (const key in Class.worldMapJsonData.paths) {
            if (Object.hasOwnProperty.call(Class.worldMapJsonData.paths, key)) {
                if (Class.coverageJsonData.countries.hasOwnProperty(key)) {
                    Class.worldMapJsonData.paths[key].name = Class.coverageJsonData.countries[key].name;
                } else {
                    Class.worldMapJsonData.paths[key].name = IsoCountries[key];
                }
            }
        }

        jsVectorMap.addMap('world', Class.worldMapJsonData);

        Class.mapRef = new window.jsVectorMap({
            selector: '#map',
            map: 'world',
            zoomOnScrollSpeed: 1,
            zoomOnScroll: true,
            zoomModifer: true,
            tooltipDistance: 15,
            regionStyle: {
                initial: {
                    fill: '#6c7178',
                    stroke: '#000000',
                    strokeWidth: 0.2,
                    fillOpacity: 1,
                },
                selected: {
                    fill: Class.primaryColorHex,
                    fillOpacity: 1,
                },
                hover: {
                    fill: Class.primaryColorHex,
                    fillOpacity: 1,
                },
            },
            showTooltip: true,
            onRegionTooltipShow(event, tooltip, code) {
                // Disable tooltip on MA border
                if (['border-ma'].indexOf(code) > -1) {
                    tooltip.css({
                        display: 'none',
                    });
                    return;
                }
                var countries = Class.coverageJsonData.countries;
                var headers = Class.coverageJsonData.headers;

                var html = '<div class="p-sm">';
                html += '<p class="font-bold text-16 mb-0">' + tooltip.text() + '</p>';

                if (countries.hasOwnProperty(code) && countries[code].hasOwnProperty('filters')) {
                    html += '<div class="font-bold mt-sm">Available technologies: </div>';
                    var technologies = Object.keys(countries[code].filters).map((key) => [key, countries[code].filters[key]]);
                    technologies.sort((a, b) => a[0].localeCompare(b[0]));
                    technologies.forEach((element, index) => {
                        if (headers[element[0]] && headers[element[0]].hide_on_frontend) {
                            return;
                        }
                        if (index !== 0) {
                            html += ', ' + element[1];
                        } else {
                            html += element[1];
                        }
                    });
                } else {
                    html += '<div class="font-bold mt-sm">Available technologies: <span class="font-normal">N/A</span></div>';
                }

                if (countries.hasOwnProperty(code) && countries[code].hasOwnProperty('networks')) {
                    var number_of_networks = Array.isArray(countries[code].networks) ? countries[code].networks.length : countries[code].networks;
                    html += '<div class="mt-sm font-bold"># of networks: <span class="font-normal"><br/>' + number_of_networks + '</span></div>';
                } else {
                    html += '<div class="mt-sm font-bold"># of networks: <span class="font-normal">N/A</span></div>';
                }

                tooltip.text(
                    html,
                    true // Enables HTML
                );

                tooltip.css({
                    backgroundColor: '#1d1d1d',
                    fontSize: '12px',
                });
            },
            onRegionClick(event, code) {
                if ($('table.data-table').length == 0) {
                    return;
                }
                Class.handleRegionClick(code);
            },
            onLoaded(map) {
                window.addEventListener('resize', () => {
                    if (window.innerWidth > 1024) {
                        map.updateSize();
                    }
                });
            },
        });
    },

    handleRegionClick(code) {
        var country = Class.coverageJsonData.countries[code].name;
        var selected = Class.locationDropdownSelectRef.val();

        if (selected.includes(country)) {
            const index = selected.indexOf(country);
            if (index > -1) {
                selected.splice(index, 1);
            }
        } else {
            selected.push(country);
        }

        if (Class.locationDropdownSelectRef) {
            Class.locationDropdownSelectRef.val(selected).trigger('change');
            Class.dropdownModel = Class.dropdownChange(Class.locationDropdownSelectRef[0].options, selected, 'location');
        }
    },
};

export default Class;
