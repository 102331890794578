const Video = {
    init() {
        Video.hoverPlayCustomerBanner();
    },
    hoverPlayCustomerBanner() {
        var customers = document.querySelectorAll(
            '.wp-block-customer-banner .customer'
        );
        customers.forEach((element) => {
            var video = element.querySelector('video');
            if (video) {
                video.pause();

                if (window.innerWidth < 640) {
                    video.play();
                }

                element.addEventListener('mouseover', function () {
                    video.play();
                });
                element.addEventListener('mouseout', function () {
                    video.pause();
                });
            }
        });
    },
};

export default Video;
