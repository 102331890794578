const UTM = {
    init() {
        // Run the UTM parameter detection on page load
        window.addEventListener('load', () => {
            UTM.getUTMParameters();

            // Check if HubSpot form exists and populate it
            UTM.handleHubSpotForms();

            // Optionally, monitor for dynamically loaded forms (e.g., via AJAX)
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'childList') {
                        UTM.handleHubSpotForms();
                    }
                });
            });

            observer.observe(document.body, { childList: true, subtree: true });
        });
    },
    // Function to get UTM parameters from URL
    getUTMParameters() {
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = {};

        // Check for UTM parameters
        ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
            const value = urlParams.get(param);
            if (value) {
                utmParams[param] = value;
            }
        });

        // Save UTM parameters in localStorage
        if (Object.keys(utmParams).length > 0) {
            localStorage.setItem('utm_params', JSON.stringify(utmParams));
        }
    },
    // Function to populate hidden form fields with UTM parameters
    populateFormWithUTM(formElement) {
        const storedUTMParams = JSON.parse(localStorage.getItem('utm_params'));

        if (storedUTMParams) {
            // Loop through the UTM parameters and match hidden form fields
            Object.keys(storedUTMParams).forEach((key) => {
                const hiddenField = formElement.querySelector(`input[name="${key}"]`);
                if (hiddenField) {
                    hiddenField.value = storedUTMParams[key];
                }
            });
        }
    },
    // Function to detect and handle HubSpot form
    handleHubSpotForms() {
        const forms = document.querySelectorAll('form[id*="hsForm"]');
        forms.forEach((form) => {
            UTM.populateFormWithUTM(form);
        });
    },
};

export default UTM;
