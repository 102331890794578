import Helpers from '../utilities/helpers';

const SvgMap = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('SvgMap', (region) => SvgMap.renderMap(region || 'DK')); // Default to UK if no isoCode provided
        });
    },
    renderMap(region) {
        return {
            init() {
                this.svgString = null;
                this.regionStats = null;
                this.checkmark = Helpers.getCheckmarkSVG();
                // Load the SVG asynchronously
                Helpers.loadMapSvg(region).then((svgString) => {
                    // Once the SVG is loaded, inject it into the DOM
                    this.svgString = svgString;
                });

                // Fetch region stats asynchronously
                Helpers.fetchRegionStats(region).then((data) => {
                    this.regionStats = data;
                });
            },
        };
    },
};

export default SvgMap;
