const Search = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('SearchForm', () => Search.SearchForm);
        });
    },
    SearchForm() {
        return {
            // other default properties
            searchWord: '',
            isLoading: false,
            searchResult: false,
            fetchSearch() {
                console.log('test');
                if (this.searchWord.length > 2) {
                    this.isLoading = true;
                    console.log(this.searchWord);
                    fetch(`/wp-json/wp/v2/search?search=${this.searchWord}&subtype=case-story,page,post,product-update,report&exclude[0]=3134`)
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data);
                            this.isLoading = false;
                            this.searchResult = data;
                            console.log(data.length);
                        });
                }
            },
        };
    },
};

export default Search;
