const Glossary = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('GlossarySearchForm', () => Glossary.GlossarySearchForm);
        });
    },
    GlossarySearchForm() {
        return {
            searchWord: '',
            isLoading: false,
            searchResult: false,
            searchData: [],
            searchLetter: '',
            init() {
                fetch(`/wp-json/wp/v2/glossary?per_page=100&orderby=title&order=asc&status=publish`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.isLoading = false;
                        this.searchResult = data;
                        this.searchData = structuredClone(data);
                    });
            },
            fetchSearch() {
                if (this.searchWord.length > 2 || this.searchWord.length == 0) {
                    this.isLoading = true;
                    console.log(this.searchWord);
                    fetch(`/wp-json/wp/v2/glossary?per_page=100&search=${this.searchWord}&orderby=title&order=asc&status=publish`)
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data);
                            this.isLoading = false;
                            this.searchResult = data;
                            console.log(data.length);
                        });
                }
            },
            filterSearchBasedFirstLetter(letter) {
                // Is not being used atm
                this.searchWord = '';
                this.searchLetter = letter;
                var filtered = this.searchData.filter((item) => {
                    let titleLower = item.title.rendered.toLowerCase();
                    return letter === '#' ? /^[0-9]/.test(titleLower) : titleLower.startsWith(letter);
                });
                this.searchResult = filtered;
            },
            scrollIntoView(letter) {
                const search_results = this.$refs.searchResults.querySelectorAll('.search-result');
                let scroll_to_element = null;
                search_results.forEach((element) => {
                    if (!scroll_to_element && element.dataset.title.toLowerCase().startsWith(letter)) {
                        console.log(element.dataset.title);
                        scroll_to_element = element;
                        return;
                    }
                });

                scroll_to_element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            },
            scrollToTop() {
                const search_results = this.$refs.searchResults.querySelectorAll('.search-result');
                search_results[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
            },
        };
    },
};

export default Glossary;
